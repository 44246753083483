<template>
  <div>
    xxx
    <el-button @click="changeAddDialog(true)">
      打开增加弹窗
    </el-button>
    <el-button @click="changeAddDialog(true, true)">
      打开修改弹窗
    </el-button>
    <el-button @click="changeChoseDialog(true)">
      打开选择弹窗
    </el-button>
    <el-upload :headers="{ token: TOKEN }" ref="upload" class="upload" name="file" :show-file-list="false" :with-credentials="true" :httpRequest="uploadHttpDefault" :on-success="uploadSuccess" accept=".xlsx,.xls,.excel,.pdf,.PDF,.doc,.docx,.jpeg,.png" :action="apis.File_ImageUpload" :on-change="fileChange" :auto-upload="true">
      <el-button type="primary">上传附件</el-button>
    </el-upload>
    <el-button @click="add({user: `user`,name: `name`})">VUEX</el-button>
    <!-- 打开/修改弹窗 -->
    <add-dialog ref="addDialog" :Language="Language" :show="addDialog.visible" :isEdit="addDialog.isEdit" @closeDialog="changeAddDialog"></add-dialog>
    <!-- 获取数据弹窗 -->
    <chose-dialog ref="choseDialog" :show="choseDialog.visible" @closeDialog="changeChoseDialog"></chose-dialog>
    <!-- 唤醒滑块验证码的按钮 -->
    <el-button @click="openDrag">打开验证码</el-button>
    <!-- 滑块验证码组件 -->
    <drag ref="drag" @close="closeDrag"></drag>
    <!-- 物资分类树 -->
    <cate-tree></cate-tree>
    <!-- 导入按钮 -->
    <import-button></import-button>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import addDialog from './dialog/addDialog.vue'
import choseDialog from './dialog/choseDialog.vue'
import drag from '@/components/drag.vue'
import mixin from '@/mixins/index'
import apis from '@/apis'
import cateTree from '@/components/cateTree.vue'
import importButton from '@/components/importButton.vue'

export default {
  mixins: [mixin],
  components: {
    addDialog,
    choseDialog,
    drag,
    cateTree,
    importButton,
  },
  data() {
    return {
      addDialog: {
        visible: false,
        isEdit: false,
      },
      choseDialog: {
        visible: false,
      },
      apis,
    }
  },
  computed: {
    ...mapState(['Language']),
  },
  methods: {
    ...mapActions({
      login: 'Login',
    }),
    /**
     * @function 改变新增/修改弹窗的状态
     * @param {Boolean} dialogStatus 是否展示当前弹窗
     * @param {Boolean} isEdit 是否是修改弹窗，如果是则要调用接口，进行回显
     * @param {Object} data 修改回显时，传给后端的值
     */
    changeAddDialog(dialogStatus, isEdit = false, data) {
      this.addDialog.isEdit = isEdit
      if (isEdit) {
        // 调用vuex里面的获取数据的业务，如果要在这里写接口，需要改成Promise
        this.$refs['addDialog'].getDataQuery(data)
      }
      // 这么写才不会影响最后的初始化，如果赋值完直接打开，会影响最后的初始化
      this.$nextTick(() => {
        this.addDialog.visible = dialogStatus
      })
    },
    /**
     * @function 改变选择弹窗的状态
     * @param {Boolean} dialogStatus 是否展示当前弹窗
     */
    changeChoseDialog(dialogStatus) {
      this.choseDialog.visible = dialogStatus
    },
    /**
     * @function 唤醒滑块验证码
     */
    openDrag() {
      this.$refs.drag.open()
    },
    /**
     * @function 关闭滑块验证码
     * @param {Number} status 1代表成功，0代表失败
     */
    closeDrag(status) {
      if (status) {
        // 执行成功的下一步
      } else {
        // 执行失败的下一步
      }
    },
  },
}
</script>
