<template>
  <div class="wrap">
    <el-popover
      trigger="hover">
      <div class="popover-wrap">
        <el-button @click="downloadFile" class="default-button download-button fl">下载导入模版</el-button>
        <el-upload
          class="upload fl"
          :show-file-list="false"
          :with-credentials="true"
          :action="uploadPath"
          :httpRequest="uploadHttpDefault"
          name="file"
          accept=".xlsx,.xls,.excel"
          :on-success="uploadSuccess"
          :on-error="uploadError">
            <el-button class="default-button upload-button">批量导入</el-button>
        </el-upload>
      </div>
      <el-button slot="reference" class="default-button upload-button">批量导入</el-button>
    </el-popover>
  </div>
</template>
<script>
import mixin from '@/mixins/index'
import apis from '@/apis'

export default {
  mixins: [mixin],
  props: {
    uploadPath: {
      required: true,
      type: String,
    },
    downloadPath: {
      required: true,
      type: String,
    },
  },
  methods: {
    /**
     * @function 下载文件
     */
    downloadFile() {
      this.exportFunc(apis.File_Download, {
        fileDirectory: this.downloadPath,
      })
    },
    /**
     * @function 上传成功
     * @param {Object} data 接口返回的数据
     */
    uploadSuccess(data) {
      this.$emit('uploadSuccess', data)
    },
    /**
     * @function 上传成功
     * @param {Object} data 接口返回的数据
     */
    uploadError(data) {
      this.$emit('uploadError', data)
    },
  },
}
</script>
<style lang="less" scoped>
  @import url('../assets/styles/mixin.scoped.less');
</style>
