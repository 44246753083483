<template>
  <div class="wrap">
    <el-tree ref="tree" :show-checkbox="showCheckbox" node-key="id" :data="tableData" :highlight-current="true" :props="defaultProps" @node-click="handleNodeClick"></el-tree>
  </div>
</template>
<script>
import apis from '@/apis'

// const _ = require('lodash')

export default {
  data() {
    return {
      tableData: [],
      defaultProps: {
        children: 'children',
        label: 'name',
      },
    }
  },
  props: {
    showCheckbox: {
      require: false,
      type: Boolean,
      default() {
        return false
      },
    },
  },
  methods: {
    /**
     * @function 点击树节点的时候，往父组件传出节点信息
     * @param {Object} data 节点信息
     * @returns {Object} 节点信息
     */
    handleNodeClick(data) {
      this.$emit('clickNode', data)
    },
    /**
     * @function 获取树状数据
     */
    getTreeData() {
      console.log('1')
      this.$http.post(apis.MaterialCategory_ListAsync).then((res) => {
        if (res.data.code === 0) {
          this.tableData = [{
            id: '',
            parentId: '0',
            name: '物资分类树',
            children: res.data.data,
          }]
        }
      })
    },
    /**
     * @function 设置树状节点的已选节点
     * @param {String} data 要设置选中的节点数据的key
     */
    setChoseNode(data) {
      this.$refs.tree.setCheckedKeys([data])
    },
    /**
     * @function 返回选中的节点
     */
    getChoseNode() {
      return this.$refs.tree.getCheckedKeys()
    },
  },
  created() {
    this.getTreeData()
  },
  activated() {
    this.getTreeData()
  },
}
</script>
