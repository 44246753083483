import pageClass from '@/unit/pageClass'

class searchFrom extends pageClass {
constructor(type) {
    super()
    if (type === 'form') {
      this.name = ''
      this.region = ''
      this.date1 = ''
      this.date2 = ''
    } else if (type === 'rule') {
      this.name = [
				{
					required: true,
					message: '请输入活动名称',
					trigger: 'blur',
				},
				{
					min: 3,
					max: 5,
					message: '长度在 3 到 5 个字符',
					trigger: 'blur',
				},
			]
			this.region = [
				{
					required: true,
					message: '请选择活动区域',
					trigger: 'change',
				},
			]
			this.date1 = [
				{
					type: 'date',
					required: true,
					message: '请选择日期',
					trigger: 'change',
				},
			]
			this.date2 = [
				{
					type: 'date',
					required: true,
					message: '请选择时间',
					trigger: 'change',
				},
			]
		}
  }
}
export default searchFrom
