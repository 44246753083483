<template>
  <div class="dialog-container">
    <el-dialog
      title="添加/修改弹窗"
      :visible.sync="show"
      @open="openDialog"
      :before-close="closeDialog"
      width="50%">
      <el-form ref="form" :model="form" label-width="80px" :rules="rules">
        <el-form-item label="活动名称" prop="name">
          <el-input clearable v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="活动区域">
          <el-select v-model="form.region" placeholder="请选择活动区域">
            <el-option label="区域一" value="shanghai"></el-option>
            <el-option label="区域二" value="beijing"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="活动时间">
          <el-col :span="11">
            <el-date-picker type="date" placeholder="选择日期" v-model="form.date1" style="width: 100%;"></el-date-picker>
          </el-col>
          <el-col class="line" :span="2">-</el-col>
          <el-col :span="11">
            <el-time-picker placeholder="选择时间" v-model="form.date2" style="width: 100%;"></el-time-picker>
          </el-col>
        </el-form-item>
        <el-form-item label="即时配送">
          <el-switch v-model="form.delivery"></el-switch>
        </el-form-item>
        <el-form-item label="活动性质">
          <el-checkbox-group v-model="form.type">
            <el-checkbox label="美食/餐厅线上活动" name="type"></el-checkbox>
            <el-checkbox label="地推活动" name="type"></el-checkbox>
            <el-checkbox label="线下主题活动" name="type"></el-checkbox>
            <el-checkbox label="单纯品牌曝光" name="type"></el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="特殊资源">
          <el-radio-group v-model="form.resource">
            <el-radio label="线上品牌商赞助"></el-radio>
            <el-radio label="线下场地免费"></el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="活动形式">
          <el-input clearable type="textarea" maxlength="100" v-model="form.desc"></el-input>
        </el-form-item>
      </el-form>
      <template slot="footer">
        <el-button @click="sureEvent">确认</el-button>
        <!-- 如果没特别需求，统一使用mixin里面的关闭方法 -->
        <el-button @click="closeDialog">取消</el-button>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import mixin from '@/mixins/dialogMixin'
import AddDialogClass from './addDialogClass'

export default {
  mixins: [mixin],
  props: {
    show: {
      required: true,
      type: Boolean,
    },
    Language: {
      required: false,
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      form: new AddDialogClass('form'), // 实例化一个表单的变量
      rules: new AddDialogClass('rule', this.Language), // 实例化一个表单的规则
    }
  },
  methods: {
    /**
     * @function 初始化弹窗
     */
    init() {
      this.$refs.form.resetFields()
      console.log('我初始化了')
    },
    /**
     * @function 点击确定按钮，先执行业务，完了调关闭方法，不要写两次关闭方法
     */
    sureEvent() {
      // 这里写业务需求
      console.log(`我执行了点确定的业务需求`)
      // 调用关闭方法，不要再写一次
      this.closeDialog()
    },
  },
}
</script>
