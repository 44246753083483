<template>
  <div class="dialog-container">
    <el-dialog
      title="选择弹窗"
      :visible.sync="show"
      @open="openDialog"
      :before-close="closeDialog"
      width="60%">
			<!-- 搜素 -->
      <el-row>
				<el-col :span="20">
					<el-form ref="form" :model="form" label-width="80px" :rules="rules">
						<el-row>
							<el-col :span="6">
								<el-form-item label="活动名称" prop="name">
									<el-input clearable v-model="form.name"></el-input>
								</el-form-item>
							</el-col>
							<el-col :span="6">
								<el-form-item label="活动区域">
										<el-select v-model="form.region" placeholder="请选择活动区域">
												<el-option label="区域一" value="shanghai"></el-option>
												<el-option label="区域二" value="beijing"></el-option>
										</el-select>
								</el-form-item>
							</el-col>
							<el-col :span="6">
								<el-form-item label="活动时间">
									<el-date-picker type="date" placeholder="选择日期" v-model="form.date1" style="width: 100%;"></el-date-picker>
								</el-form-item>
							</el-col>
						</el-row>
					</el-form>
				</el-col>
				<el-col :span="4">
					<el-button @click="searchEvent">搜索</el-button>
				</el-col>
      </el-row>
			<!-- 表格 -->
			<el-table
				:data="tableData"
				style="width: 100%"
				@selection-change="handleSelectionChange">
				<el-table-column
					type="selection"
					width="55">
				</el-table-column>
				<el-table-column
					prop="date"
					label="日期"
					width="180">
				</el-table-column>
				<el-table-column
					prop="name"
					label="姓名"
					width="180">
				</el-table-column>
				<el-table-column
					prop="address"
					label="地址">
				</el-table-column>
			</el-table>
			<!-- 分页 -->
			<el-pagination
				@size-change="handleSizeChange"
				@current-change="handleCurrentChange"
				:current-page="form.pageNum"
				:page-sizes="[10, 20, 30, 40]"
				:page-size="form.pageSize"
				layout="total, sizes, prev, pager, next, jumper"
				:total="form.total">
			</el-pagination>
      <template slot="footer">
        <el-button @click="sureEvent">确认</el-button>
        <!-- 如果没特别需求，统一使用mixin里面的关闭方法 -->
        <el-button @click="closeDialog">取消</el-button>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import mixin from '@/mixins/dialogMixin'
import ChoseDialogClass from './choseDialogClass'

export default {
  mixins: [mixin],
  props: {
    show: {
      required: true,
      type: Boolean,
    },
  },
  data() {
    return {
      form: new ChoseDialogClass('form'), // 实例化一个表单的变量
			rules: new ChoseDialogClass('rule'), // 实例化一个表单的规则
			searchWidthOpen: true, // 是否一打开就自动搜索
			tableData: [
				{
					date: '2016-05-02',
					name: '王小虎',
					address: '上海市普陀区金沙江路 1518 弄',
				}, {
					date: '2016-05-04',
					name: '王小虎',
					address: '上海市普陀区金沙江路 1517 弄',
				}, {
					date: '2016-05-01',
					name: '王小虎',
					address: '上海市普陀区金沙江路 1519 弄',
				}, {
					date: '2016-05-03',
					name: '王小虎',
					address: '上海市普陀区金沙江路 1516 弄',
				},
			], // 表格数据
    }
  },
  methods: {
    /**
     * @function 初始化弹窗
     */
    init() {
      this.$refs.form.resetFields()
      console.log('我初始化了')
    },
    /**
     * @function 点击确定按钮，先执行业务，完了调关闭方法，不要写两次关闭方法
     */
    sureEvent() {
      // 这里写业务需求,比如判断选中几条啊，调用接口什么的
      console.log(`我执行了点确定的业务需求`)
      // 调用关闭方法，不要再写一次
      this.closeDialog()
		},
		/**
		 * @function 搜索功能,分页在这边不考虑，只写获取数据的业务
		 */
		searchData() {
			console.log(`在这里执行搜索操作，请求第${this.form.pageNum}页，每页${this.form.pageSize}条`)
			// 因为展示需要赋值分页，实际开发要删除
			this.form.total = 100
		},
  },
}
</script>
