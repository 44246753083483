import zh from '@/locales/zh.json'
import en from '@/locales/en.json'

const Lang = {
  zh,
  en,
}
class searchFrom {
  constructor(type, lanuage = 0) {
    if (type === 'form') {
      this.name = ''
      this.region = ''
      this.date1 = ''
      this.date2 = ''
      this.delivery = false
      this.type = []
      this.resource = ''
      this.desc = ''
    } else if (type === 'rule') {
      this.name = [
        {
          required: true,
          message: Lang[lanuage === 1 ? `en` : `zh`].ple_name,
          trigger: 'blur',
        },
        {
          min: 3,
          max: 5,
          message: '长度在 3 到 5 个字符',
          trigger: 'blur',
        },
      ]
      this.region = [
        {
          required: true,
          message: '请选择活动区域',
          trigger: 'change',
        },
      ]
      this.date1 = [
        {
          type: 'date',
          required: true,
          message: '请选择日期',
          trigger: 'change',
        },
      ]
      this.date2 = [
        {
          type: 'date',
          required: true,
          message: '请选择时间',
          trigger: 'change',
        },
      ]
      this.type = [
        {
          type: 'array',
          required: true,
          message: '请至少选择一个活动性质',
          trigger: 'change',
        },
      ]
      this.resource = [
        {
          required: true,
          message: '请选择活动资源',
          trigger: 'change',
        },
      ]
      this.desc = [
        {
          required: true,
          message: '请填写活动形式',
          trigger: `blur`,
        },
      ]
    }
  }
}
export default searchFrom
